import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/Layout"
import Block from "../components/ui/Block"
import Card from "../components/ui/Card"
import CTA from "../components/ui/CTA"
import FlexContainer from "../components/ui/FlexContainer"
import Gallery from "../components/ui/Gallery"
import { StructuredText } from "react-datocms"

export default function Sportarten({ data }) {
  const content = data?.datoCmsVerein
  const pageContext = {
    seo: {
      title: "Turnverein Lahr",
      description:
        "Der Turnverein Lahr von 1846 e.V. zählt zu den ältesten Turnvereinen in Baden- Württemberg und ist mit nahezu 1700 Mitgliedern der größte Sport anbietende Verein in Lahr.",
      robots: ["index, follow"],
      path: "/verein",
      lang: "de",
    },
  }
  return (
    <Layout pageContext={pageContext}>
      <Block
        note={content?.tag}
        title={content?.headline}
        content={content?.content}
      />
      <Block id="vorstand" title="Geschäftsführender Vorstand" marginBottom={0}/>
      <FlexContainer gap="24px" className="main-container" direction="left">
        {content?.vorstand?.map(item => (
          <Card
            imageUrl={item?.image?.url}
            title={item?.name}
            content={item?.description}
            note={item?.work}
            type="person"
          />
        ))}
      </FlexContainer>
      <Block
        title="Erweiterter Vorstand"
        content={
          <StructuredText
            data={content?.erweiterterVorstandInhalt}
            renderBlock={({ record }) => {
              switch (record.__typename) {
                case "DatoCmsGallery":
                  return <Gallery media={record?.media} />
                default:
                  return null
              }
            }}
          />
        }
        textCenter
      >
        {/* <img src="https://s3-alpha-sig.figma.com/img/ee9d/ab12/3101a233ea3345ae67170a6871a4834c?Expires=1660521600&Signature=BXrRMJIMDc0IyHwlRYFbUrAgx~D27XbpyKepxxxc8n7FVLiWwlR4BfBRrihlU8SsmlUmbUMcUCECOjqYAsHzBvC24uipOk3q4OzaNAwt2P8CFozeI0TiMYCiCy7vNhAPi7lX66cM5gBHMQkMsvqxoImCm15K0dWOYvVSJXc1JRi6mxkVWnxsI3sm9gTLFj7IvzCerDQQlWnHUlGBPLPa2uGRqXwgfzL9s6ym4zuWSWHdwRlMZVP8UDBlL6iMRbS9lHoKqizRvJoyhENCH8ga1CWiX3WTXM1yqBEwpAJtrJUosQigUiVjeO~A8EaGMpV4d1WVTNuxFIugOp33Wzs2fw__&Key-Pair-Id=APKAINTVSUGEWH5XD5UA"/> */}
        {/* <Gallery
          media={[
            {
              url: "https://s3-alpha-sig.figma.com/img/ee9d/ab12/3101a233ea3345ae67170a6871a4834c?Expires=1660521600&Signature=BXrRMJIMDc0IyHwlRYFbUrAgx~D27XbpyKepxxxc8n7FVLiWwlR4BfBRrihlU8SsmlUmbUMcUCECOjqYAsHzBvC24uipOk3q4OzaNAwt2P8CFozeI0TiMYCiCy7vNhAPi7lX66cM5gBHMQkMsvqxoImCm15K0dWOYvVSJXc1JRi6mxkVWnxsI3sm9gTLFj7IvzCerDQQlWnHUlGBPLPa2uGRqXwgfzL9s6ym4zuWSWHdwRlMZVP8UDBlL6iMRbS9lHoKqizRvJoyhENCH8ga1CWiX3WTXM1yqBEwpAJtrJUosQigUiVjeO~A8EaGMpV4d1WVTNuxFIugOp33Wzs2fw__&Key-Pair-Id=APKAINTVSUGEWH5XD5UA",
              alt: "team",
            },
          ]}
        />
        <p>
          Hintere Reihe von links: Jürgen Katinger/Repräsentation, Claudia
          Stihler/Fitness-Gesundheitssport, Jessica Hartmann/Dance, Claudia
          Michel/Veranstaltungen/Innere Organisation, Rainer Göhringer/Fechten,
          Renate Eckenfels/Geschäftsstelle, Manuel Ludwig/Logistik/Kunstturnen,
          Ludwig Hillenbrand/Schriftleiter Vereinszeitschrif, Steffen
          Laube/Wettkampfabteilungen{" "}
        </p> */}
      </Block>
      <Block title="Geschäftsstelle Ansprechpartner" marginBottom={0}/>
      <FlexContainer gap="24px" className="main-container" direction="left">
        {content?.geschaeftsstelleAnsprechpartner?.map(item => (
          <Card
            imageUrl={item?.image?.url}
            title={item?.name}
            content={item?.description}
            note={item?.work}
            type="person"
          />
        ))}
      </FlexContainer>
      <CTA />
    </Layout>
  )
}
export const query = graphql`
  query VereinQuery {
    datoCmsVerein {
      tag
      content
      headline
      geschaeftsstelleAnsprechpartner {
        description
        image {
          alt
          url
        }
        name
        work
      }
      erweiterterVorstandInhalt {
        blocks {
          __typename
          ... on DatoCmsGallery {
            id: originalId
            media {
              alt
              url
            }
          }
        }
        value
      }
      geschaeftsstelleInhalt {
        blocks {
          __typename
          ... on DatoCmsGallery {
            id: originalId
            media {
              alt
              url
            }
          }
        }
        value
      }
      vorstand {
        description
        image {
          alt
          url
        }
        name
        work
      }
    }
  }
`
